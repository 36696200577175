:root {
  /* Variables */
  --offset-x: 0px;
  --offset-y: 0px;
  --grid-size: 20px;
  --background: rgba(255, 255, 255, 0.276);
  /* --background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAYAAAA8AXHiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxEAAAsRAX9kX5EAAAH6SURBVHhe7d0xSsRAGIDRrNexsBGsxDPYuVYeygNYud7DSrCx8Dzrbkxr5weZ8B4MmUw38JGU/3R2nKa7eTOy5+P9shvbRu6xW6K6Oq2v+WRQ+6fX28PL4/vyOqyt3GPmi7UiG7nHxfKEfyUsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiKxO8/He/g8XL5d77+Xs1HdnNbH73ZoW7mHQZirYhAm/E1YJIRFQlgkhEVCWCSERUJYJIRFQlgkhEVCWCSERUJYJIRFQlgkhEVCWCSERUJYJIRFQlgkhEVCWCSERUJYJIRFQlgkhEVCWCSERUJYJIRFQlgkhEVCWCSERUJYJIRFQlgkhEVCWCSERUJYJIRFQlgkhEVCWCRMWF0fE1ZXxYTVVfErJCEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLBLCIiEsEsIiISwSwiIhLALT9ANicS9Aoa5dEgAAAABJRU5ErkJggg==') */
}

.canvas {
  width: calc(100vw);
  height: calc(68vh);
  /* width: 100%; */
  /* background: rgba(116, 116, 116, 0.345); */
  align-self: left;
  float: left;

  background-color: var(--background);

  background-position-x: var(--offset-x);
  background-position-y: var(--offset-y);

  background-size: calc(var(--grid-size) * 3) calc(var(--grid-size) * 3);

  /* background-image: var(--background-image); */

}

.container-fw {
  width: 100%;
}

.ports-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-right: 10px;
  }

  &:only-child {
    margin-right: 0px;
  }
}

.ports {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
}


.title {
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  white-space: nowrap;
  justify-items: center;
}

.title-name {
  flex-grow: 1;
  padding: 5px 5px;
}

.description {
  flex-grow: 1;
  display: flex;
  padding: 5px;
  color: #ffffff;
  background: rgba(200, 200, 200, 0.5);
}

.general-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.4);
}

.common {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: rgba(100, 0, 100, 0.4);
}

.common-title {
  flex-direction: column;
  background: rgba(100, 0, 100, 0.4);
  size: 12px
}

.variables {
  background: rgba(255, 255, 255, 0.8);
  color: black;
  text-align: left;
  border: 1px solid black;
  border-collapse: collapse;
  border-color: black;
}

.variables tr:nth-child(odd) {
  background-color: initial;
  color: rgb(0, 0, 0);
}

.transport-parameters {
  font-family: "Lucida Console", "Courier New", monospace;
  background: rgb(223, 220, 220, 0.7);
  color: black;
  text-align: left;
  width: 100%;
  /* border: 1px solid; */
  /* border-collapse: collapse; */
  /* border-color: black; */
}

.transport-parameters tr::nth-of-type(odd) {
  background-color: initial;
  color: rgb(0, 0, 0);
}

.config-parameters {
  font-family: "Lucida Console", "Courier New", monospace;
  background: rgb(223, 220, 220, 0.8);
  color: black;
  text-align: left;
  /* border: 1px solid; */
  /* border-collapse: collapse; */
  /* border-color: black; */
}

.config-parameters tr::nth-of-type(odd) {
  background-color: initial;
  color: rgb(0, 0, 0);
}

.mapping-values {
  font-family: "Lucida Console", "Courier New", monospace;
  background: rgb(223, 220, 220, 0.8);
  color: black;
  text-align: left;
  max-height: 150px;
  overflow-x: hidden;
  /* border: 1px solid; */
  /* border-collapse: collapse; */
  /* border-color: black; */
}

.mapping-values tr::nth-of-type(odd) {
  background-color: initial;
  color: rgb(0, 0, 0);
}

.tunnel-shadow {
  box-shadow: rgba(0, 0, 0, 0.8) 10px 10px 20px;
}

.tunnel-widget {
  border-radius: 5px;
  font-family: sans-serif;
  color: white;
  border: solid 2px black;
  overflow: visible;
  font-size: 11px;
  min-width: 800px;
}

.filetunnel-widget {
  border-radius: 5px;
  font-family: sans-serif;
  color: white;
  border: solid 2px black;
  overflow: visible;
  font-size: 11px;
  min-width: 800px;
}

.node-widget {
  border-radius: 5px;
  font-family: sans-serif;
  color: white;
  border: solid 2px black;
  overflow: visible;
  font-size: 11px;
  min-width: 400px;
}

.function-widget {
  min-width: 700px;
}

.transformer-textarea {
  font-size: 10px;
  width: 100%;
  max-height: 2000px;
  min-height: 200px;
  ;
}

.detail-button {
  border-radius: 5px;
  border-width: 1px;
}

.port-label {
  display: flex;
  margin-top: 1px;
  align-items: center;
  border-radius: 8px 8px 8px 8px;
}

.port-label:hover {
  background: rgba(255, 255, 255, 0.7);
  color: rgb(0, 0, 0);
}

.port-label-label {
  padding: 0 5px;
  flex-grow: 1;
}