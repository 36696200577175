body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-tree-toggler.active {
  transform: scale(1.5) rotate(45deg);
  color: rgb(149, 149, 149);
  margin-right: 6px;
  -webkit-transition: -webkit-transform .2s linear;
}

.d-inline {
  width: 10px;
  /* margin-right: 4px; */
  padding-right: 5px;
  -webkit-transition: -webkit-transform .2s linear;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-lg,
.text-xl {
  line-height: 1.75rem;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-text-opacity));
}

.text-flat {
  font-size: 12px;
  margin-top: -5px;
}

.font-bold {
  font-weight: 700;
}

.grey-bar {
  background-color: rgb(240, 240, 240);
  height: 3px;
}

.standard-button {
  height: 35px;
  font-size: 12px;
  border-radius: 4px;
}

.e-badge-name {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: wrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  --bs-bg-opacity: 1;
  background-color: burlywood !important;
}

.e-badge-tunnel {
  background-color: rgb(33, 161, 42); /* Override background-color */
}

.badge-fixed {
  width: 120px;
}

.badge-160 {
  width: 160px;
}

.badge-150 {
  width: 150px;
}

.badge-140 {
  width: 140px;
}

.badge-140 {
  width: 120px;
}

.badge-100 {
  width: 100px;
}

.badge-90 {
  width: 90px;
}

.badge-80 {
  width: 80px;
}

.badge-60 {
  width: 60px;
}

.badge-40 {
  width: 40px;
}

.nowrap {
  white-space: nowrap;
}

.wrap {
  white-space: wrap;
}

.overflow {
  overflow: scroll;
}

th,
td {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
}

.variable-table {
  background-color: white;
  border-collapse: separate;
  border: solid rgb(169, 169, 169) 1px;
  border-radius: 10px;
  padding: 5px;
  width: fit-content;
}

.variable-table tr:nth-child(odd) {
  background-color: #b9ceff;
  color: rgb(0, 0, 0);
}

.bg-white {
  background-color: white !important;
}

.add_red {
  color: red;
  font-weight: bold;
}

.diagram-container {
  width: calc(100vw);
  /* height: 100%; */
  height: calc(70vh);
  width: 100%;
  background: rgb(245, 245, 245);
  align-self: left;
}

.container-fw {
  width: 100%;
}

.float-container {
  float: left;
  position: relative;
  left: 10px;
  top: 10px;
  z-index: 1000;
  /* background-color: #4242424d; */
  /* padding: 5px; */
  /* border-color: #2c2e31;
  border-width: 1px;
  border-radius: 10px; */
  /* color: #FFFFFF20; */
}

.transparent-70 {
  opacity: 0.7 !important;
}

.modal-backdrop {
  opacity: 0.2 !important;
}

.active-border {
  color: aqua;
  border: #477eff;
  border-width: 2px;
  border-color: rgb(252, 9, 9);
}

.swatch {
  padding: '5px';
  background: '#fff';
  border-radius: '1px';
  box-shadow: '0 0 0 1px rgba(0,0,0,.1)';
  display: 'inline-block';
  cursor: 'pointer';
}

.popover {
  position: absolute;
  z-index: 2;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 15px;
  height: 15px;
  padding: 0px 0px 0px 0px;
  /* margin-left: 2px; */
  /* margin-left: auto; */
  margin-right: 4px;
  float: left;
  border-radius: 7px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.2);
  /* text-align: center;
    font-size: 12px;
    line-height: 1.42857; */
}

.selected-border {
  border-color: rgba(137, 43, 226, 0.324);
  border-width: 2px;
}